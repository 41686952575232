@font-face {
  font-family: 'CoreUI-Icons-Linear';
  src: url("../fonts/CoreUI-Icons-Linear.eot?64h6xh");
  src: url("../fonts/CoreUI-Icons-Linear.eot?64h6xh#iefix") format("embedded-opentype"), url("../fonts/CoreUI-Icons-Linear.ttf?64h6xh") format("truetype"), url("../fonts/CoreUI-Icons-Linear.woff?64h6xh") format("woff"), url("../fonts/CoreUI-Icons-Linear.svg?64h6xh#CoreUI-Icons-Linear") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="cui-"], [class*=" cui-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'CoreUI-Icons-Linear' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cui-accessible:before {
  content: "\e900";
}

.cui-account-logout:before {
  content: "\e901";
}

.cui-action-redo:before {
  content: "\e902";
}

.cui-action-undo:before {
  content: "\e903";
}

.cui-address-book:before {
  content: "\e904";
}

.cui-address-card:before {
  content: "\e905";
}

.cui-align-center:before {
  content: "\e906";
}

.cui-align-left:before {
  content: "\e907";
}

.cui-align-right:before {
  content: "\e908";
}

.cui-aperture:before {
  content: "\e909";
}

.cui-archive:before {
  content: "\e90a";
}

.cui-arrow-bottom:before {
  content: "\e90b";
}

.cui-arrow-circle-bottom:before {
  content: "\e90c";
}

.cui-arrow-circle-left:before {
  content: "\e90d";
}

.cui-arrow-circle-right:before {
  content: "\e90e";
}

.cui-arrow-circle-top:before {
  content: "\e90f";
}

.cui-arrow-left:before {
  content: "\e910";
}

.cui-arrow-right:before {
  content: "\e911";
}

.cui-arrow-thick-bottom:before {
  content: "\e912";
}

.cui-arrow-thick-circle-down:before {
  content: "\e913";
}

.cui-arrow-thick-circle-left:before {
  content: "\e914";
}

.cui-arrow-thick-circle-right:before {
  content: "\e915";
}

.cui-arrow-thick-circle-up:before {
  content: "\e916";
}

.cui-arrow-thick-from-bottom:before {
  content: "\e917";
}

.cui-arrow-thick-from-left:before {
  content: "\e918";
}

.cui-arrow-thick-from-right:before {
  content: "\e919";
}

.cui-arrow-thick-from-top:before {
  content: "\e91a";
}

.cui-arrow-thick-left:before {
  content: "\e91b";
}

.cui-arrow-thick-right:before {
  content: "\e91c";
}

.cui-arrow-thick-square-down:before {
  content: "\e91d";
}

.cui-arrow-thick-square-left:before {
  content: "\e91e";
}

.cui-arrow-thick-square-right:before {
  content: "\e91f";
}

.cui-arrow-thick-square-up:before {
  content: "\e920";
}

.cui-arrow-thick-to-bottom:before {
  content: "\e921";
}

.cui-arrow-thick-to-left:before {
  content: "\e922";
}

.cui-arrow-thick-to-right:before {
  content: "\e923";
}

.cui-arrow-thick-to-top:before {
  content: "\e924";
}

.cui-arrow-thick-top:before {
  content: "\e925";
}

.cui-arrow-top:before {
  content: "\e926";
}

.cui-assistive-listening-system:before {
  content: "\e927";
}

.cui-at:before {
  content: "\e928";
}

.cui-audio:before {
  content: "\e929";
}

.cui-audio-description:before {
  content: "\e92a";
}

.cui-audio-spectrum:before {
  content: "\e92b";
}

.cui-badge:before {
  content: "\e92c";
}

.cui-balance-scale:before {
  content: "\e92d";
}

.cui-balance-scale-left:before {
  content: "\e92e";
}

.cui-balance-scale-right:before {
  content: "\e92f";
}

.cui-ban:before {
  content: "\e930";
}

.cui-bar-chart:before {
  content: "\e931";
}

.cui-barcode:before {
  content: "\e932";
}

.cui-bath:before {
  content: "\e934";
}

.cui-battery-empty:before {
  content: "\e935";
}

.cui-battery-full:before {
  content: "\e936";
}

.cui-beaker:before {
  content: "\e937";
}

.cui-bell:before {
  content: "\e938";
}

.cui-bell-slash:before {
  content: "\e939";
}

.cui-blind:before {
  content: "\e93a";
}

.cui-bluetooth:before {
  content: "\e93b";
}

.cui-bold:before {
  content: "\e93c";
}

.cui-bolt:before {
  content: "\e93d";
}

.cui-book:before {
  content: "\e93e";
}

.cui-book-open:before {
  content: "\e93f";
}

.cui-bookmark:before {
  content: "\e940";
}

.cui-box:before {
  content: "\e941";
}

.cui-braille:before {
  content: "\e942";
}

.cui-briefcase:before {
  content: "\e943";
}

.cui-british-pound:before {
  content: "\e944";
}

.cui-broadcast-tower:before {
  content: "\e945";
}

.cui-browser:before {
  content: "\e947";
}

.cui-brush:before {
  content: "\e948";
}

.cui-bug:before {
  content: "\e949";
}

.cui-building:before {
  content: "\e94a";
}

.cui-bullhorn:before {
  content: "\e94b";
}

.cui-calculator:before {
  content: "\e94c";
}

.cui-calendar:before {
  content: "\e94d";
}

.cui-calendar-check:before {
  content: "\e94e";
}

.cui-calendar-minus:before {
  content: "\e94f";
}

.cui-calendar-plus:before {
  content: "\e950";
}

.cui-calendar-times:before {
  content: "\e951";
}

.cui-camera:before {
  content: "\e952";
}

.cui-caret-bottom:before {
  content: "\e953";
}

.cui-caret-left:before {
  content: "\e954";
}

.cui-caret-right:before {
  content: "\e955";
}

.cui-caret-top:before {
  content: "\e956";
}

.cui-cart:before {
  content: "\e957";
}

.cui-cart-arrow-down:before {
  content: "\e958";
}

.cui-cart-loaded:before {
  content: "\e933";
}

.cui-chalkboard:before {
  content: "\e959";
}

.cui-chalkboard-teacher:before {
  content: "\e95a";
}

.cui-chart:before {
  content: "\e95b";
}

.cui-chart-area:before {
  content: "\e95c";
}

.cui-chart-line:before {
  content: "\e95d";
}

.cui-chart-pie:before {
  content: "\ea54";
}

.cui-chat:before {
  content: "\e95e";
}

.cui-chat-bubble:before {
  content: "\e95f";
}

.cui-check:before {
  content: "\e960";
}

.cui-chevron-bottom:before {
  content: "\e961";
}

.cui-chevron-circle-down:before {
  content: "\e962";
}

.cui-chevron-circle-left:before {
  content: "\e963";
}

.cui-chevron-circle-right:before {
  content: "\e964";
}

.cui-chevron-circle-up:before {
  content: "\e965";
}

.cui-chevron-double-down:before {
  content: "\e966";
}

.cui-chevron-double-left:before {
  content: "\e967";
}

.cui-chevron-double-right:before {
  content: "\e968";
}

.cui-chevron-double-up:before {
  content: "\e969";
}

.cui-chevron-left:before {
  content: "\e96a";
}

.cui-chevron-right:before {
  content: "\e96b";
}

.cui-chevron-square-down:before {
  content: "\e96c";
}

.cui-chevron-square-left:before {
  content: "\e96d";
}

.cui-chevron-square-right:before {
  content: "\e96e";
}

.cui-chevron-square-up:before {
  content: "\e96f";
}

.cui-chevron-top:before {
  content: "\e970";
}

.cui-circle:before {
  content: "\e971";
}

.cui-circle-check:before {
  content: "\e972";
}

.cui-circle-x:before {
  content: "\e973";
}

.cui-clipboard:before {
  content: "\e974";
}

.cui-clock:before {
  content: "\e975";
}

.cui-clone:before {
  content: "\e976";
}

.cui-closed-captioning:before {
  content: "\e977";
}

.cui-cloud:before {
  content: "\e978";
}

.cui-cloud-download:before {
  content: "\e979";
}

.cui-cloud-upload:before {
  content: "\e97a";
}

.cui-cloudy:before {
  content: "\e97b";
}

.cui-code:before {
  content: "\e97c";
}

.cui-coffee:before {
  content: "\e97d";
}

.cui-cog:before {
  content: "\e97e";
}

.cui-collapse-down:before {
  content: "\e97f";
}

.cui-collapse-left:before {
  content: "\e980";
}

.cui-collapse-right:before {
  content: "\e981";
}

.cui-collapse-up:before {
  content: "\e982";
}

.cui-columns:before {
  content: "\e983";
}

.cui-command:before {
  content: "\e984";
}

.cui-comment-bubble:before {
  content: "\e985";
}

.cui-comment-bubble-check:before {
  content: "\e986";
}

.cui-comment-bubble-edit:before {
  content: "\e987";
}

.cui-comment-bubble-exclamation:before {
  content: "\e988";
}

.cui-comment-bubble-lines:before {
  content: "\e989";
}

.cui-comment-bubble-minus:before {
  content: "\e98a";
}

.cui-comment-bubble-plus:before {
  content: "\e98b";
}

.cui-comment-bubble-smile:before {
  content: "\e98c";
}

.cui-comment-bubble-x:before {
  content: "\e98d";
}

.cui-comment-square:before {
  content: "\e98e";
}

.cui-comment-square-check:before {
  content: "\e98f";
}

.cui-comment-square-edit:before {
  content: "\e990";
}

.cui-comment-square-exclamation:before {
  content: "\e991";
}

.cui-comment-square-lines:before {
  content: "\e992";
}

.cui-comment-square-minus:before {
  content: "\e993";
}

.cui-comment-square-plus:before {
  content: "\e994";
}

.cui-comment-square-smile:before {
  content: "\e995";
}

.cui-comment-square-x:before {
  content: "\e996";
}

.cui-compass:before {
  content: "\e997";
}

.cui-compress:before {
  content: "\e998";
}

.cui-compress-wide:before {
  content: "\e999";
}

.cui-contrast:before {
  content: "\e99a";
}

.cui-copy:before {
  content: "\e99b";
}

.cui-copyright:before {
  content: "\e99c";
}

.cui-copywriting:before {
  content: "\e99d";
}

.cui-credit-card:before {
  content: "\e99e";
}

.cui-crop:before {
  content: "\e99f";
}

.cui-cursor:before {
  content: "\e9a0";
}

.cui-cursor-move:before {
  content: "\e9a1";
}

.cui-cut:before {
  content: "\e9a2";
}

.cui-dashboard:before {
  content: "\e9a3";
}

.cui-data-transfer-download:before {
  content: "\e9a4";
}

.cui-data-transfer-upload:before {
  content: "\e9a5";
}

.cui-deaf:before {
  content: "\e9a6";
}

.cui-delete:before {
  content: "\e9a7";
}

.cui-dial:before {
  content: "\e9a8";
}

.cui-document:before {
  content: "\e9a9";
}

.cui-dollar:before {
  content: "\e9aa";
}

.cui-donate:before {
  content: "\e9ab";
}

.cui-double-quote-sans-left:before {
  content: "\e9ac";
}

.cui-double-quote-sans-right:before {
  content: "\e9ad";
}

.cui-double-quote-serif-left:before {
  content: "\e9ae";
}

.cui-double-quote-serif-right:before {
  content: "\e9af";
}

.cui-drop:before {
  content: "\e9b0";
}

.cui-eject:before {
  content: "\e9b1";
}

.cui-elevator:before {
  content: "\e9b2";
}

.cui-ellipses:before {
  content: "\e9b3";
}

.cui-energy:before {
  content: "\e9b4";
}

.cui-envelope-closed:before {
  content: "\e9b5";
}

.cui-envelope-letter:before {
  content: "\e9b6";
}

.cui-envelope-open:before {
  content: "\e9b7";
}

.cui-equalizer:before {
  content: "\e9b8";
}

.cui-eraser:before {
  content: "\e9b9";
}

.cui-euro:before {
  content: "\e9ba";
}

.cui-excerpt:before {
  content: "\e9bb";
}

.cui-expand-down:before {
  content: "\e9bc";
}

.cui-expand-left:before {
  content: "\e9bd";
}

.cui-expand-right:before {
  content: "\e9be";
}

.cui-expand-up:before {
  content: "\e9bf";
}

.cui-external-link:before {
  content: "\e9c0";
}

.cui-eye:before {
  content: "\e9c1";
}

.cui-eye-slash:before {
  content: "\e9c2";
}

.cui-eyedropper:before {
  content: "\e9c3";
}

.cui-fax:before {
  content: "\e9c4";
}

.cui-file:before {
  content: "\e9c5";
}

.cui-file-acrobat:before {
  content: "\e9c6";
}

.cui-file-archive:before {
  content: "\e9c7";
}

.cui-file-audio:before {
  content: "\e9c8";
}

.cui-file-code:before {
  content: "\e9c9";
}

.cui-file-doc:before {
  content: "\e9ca";
}

.cui-file-excel:before {
  content: "\e9cb";
}

.cui-file-image:before {
  content: "\e9cc";
}

.cui-file-pdf:before {
  content: "\e9cd";
}

.cui-file-powerpoint:before {
  content: "\e9ce";
}

.cui-file-ppt:before {
  content: "\e9cf";
}

.cui-file-video:before {
  content: "\e9d0";
}

.cui-file-word:before {
  content: "\e9d1";
}

.cui-file-xls:before {
  content: "\e9d2";
}

.cui-film:before {
  content: "\e9d3";
}

.cui-filter:before {
  content: "\e9d4";
}

.cui-fire:before {
  content: "\e9d5";
}

.cui-flag:before {
  content: "\e9d6";
}

.cui-flash:before {
  content: "\e9d7";
}

.cui-folder:before {
  content: "\e9d8";
}

.cui-folder-open:before {
  content: "\e9d9";
}

.cui-fork:before {
  content: "\e9da";
}

.cui-frown:before {
  content: "\e9db";
}

.cui-fullscreen-enter:before {
  content: "\e9dc";
}

.cui-fullscreen-exit:before {
  content: "\e9dd";
}

.cui-futbol:before {
  content: "\e9de";
}

.cui-gem:before {
  content: "\e9df";
}

.cui-gift:before {
  content: "\e9e0";
}

.cui-glasses:before {
  content: "\e9e1";
}

.cui-globe:before {
  content: "\e9e2";
}

.cui-graph:before {
  content: "\e9e3";
}

.cui-grid-four-up:before {
  content: "\e9e4";
}

.cui-grid-three-up:before {
  content: "\e9e5";
}

.cui-grid-two-up:before {
  content: "\e9e6";
}

.cui-hand-lizard:before {
  content: "\e9e7";
}

.cui-hand-paper:before {
  content: "\e9e8";
}

.cui-hand-peace:before {
  content: "\e9e9";
}

.cui-hand-point-down:before {
  content: "\e9ea";
}

.cui-hand-point-left:before {
  content: "\e9eb";
}

.cui-hand-point-right:before {
  content: "\e9ec";
}

.cui-hand-point-up:before {
  content: "\e9ed";
}

.cui-hand-pointer:before {
  content: "\e9ee";
}

.cui-hand-rock:before {
  content: "\e9ef";
}

.cui-hand-scissors:before {
  content: "\e9f0";
}

.cui-hand-spock:before {
  content: "\e9f1";
}

.cui-handshake:before {
  content: "\e9f2";
}

.cui-hard-drive:before {
  content: "\e9f3";
}

.cui-header:before {
  content: "\e9f4";
}

.cui-headphones:before {
  content: "\e9f5";
}

.cui-heart:before {
  content: "\e9f6";
}

.cui-highlighter:before {
  content: "\e9f7";
}

.cui-history:before {
  content: "\e9f8";
}

.cui-home:before {
  content: "\e9f9";
}

.cui-hospital:before {
  content: "\e9fa";
}

.cui-hourglass:before {
  content: "\e9fb";
}

.cui-id-badge:before {
  content: "\e9fc";
}

.cui-id-card:before {
  content: "\e9fd";
}

.cui-image:before {
  content: "\e9fe";
}

.cui-images:before {
  content: "\e9ff";
}

.cui-inbox:before {
  content: "\ea00";
}

.cui-inbox-in:before {
  content: "\ea01";
}

.cui-inbox-out:before {
  content: "\ea02";
}

.cui-industry:before {
  content: "\ea03";
}

.cui-infinity:before {
  content: "\ea04";
}

.cui-info:before {
  content: "\ea05";
}

.cui-italic:before {
  content: "\ea06";
}

.cui-justify-center:before {
  content: "\ea07";
}

.cui-justify-left:before {
  content: "\ea08";
}

.cui-justify-right:before {
  content: "\ea09";
}

.cui-key:before {
  content: "\ea0a";
}

.cui-keyboard:before {
  content: "\ea0b";
}

.cui-language:before {
  content: "\ea0c";
}

.cui-laptop:before {
  content: "\ea0d";
}

.cui-layers:before {
  content: "\ea0e";
}

.cui-lemon:before {
  content: "\ea0f";
}

.cui-level-down:before {
  content: "\ea10";
}

.cui-level-up:before {
  content: "\ea11";
}

.cui-life-ring:before {
  content: "\ea12";
}

.cui-lightbulb:before {
  content: "\ea13";
}

.cui-link-broken:before {
  content: "\e946";
}

.cui-link-intact:before {
  content: "\ea14";
}

.cui-list:before {
  content: "\ea15";
}

.cui-list-rich:before {
  content: "\ea16";
}

.cui-location-pin:before {
  content: "\ea17";
}

.cui-lock-locked:before {
  content: "\ea18";
}

.cui-lock-unlocked:before {
  content: "\ea19";
}

.cui-loop:before {
  content: "\ea1a";
}

.cui-loop-1:before {
  content: "\ea1b";
}

.cui-loop-circular:before {
  content: "\ea1c";
}

.cui-loop-square:before {
  content: "\ea1d";
}

.cui-low-vision:before {
  content: "\ea1e";
}

.cui-magnifying-glass:before {
  content: "\ea1f";
}

.cui-map:before {
  content: "\ea20";
}

.cui-map-alt:before {
  content: "\ea21";
}

.cui-media-pause:before {
  content: "\ea22";
}

.cui-media-play:before {
  content: "\ea23";
}

.cui-media-record:before {
  content: "\ea24";
}

.cui-media-skip-backward:before {
  content: "\ea25";
}

.cui-media-skip-forward:before {
  content: "\ea26";
}

.cui-media-step-backward:before {
  content: "\ea27";
}

.cui-media-step-forward:before {
  content: "\ea28";
}

.cui-media-stop:before {
  content: "\ea29";
}

.cui-medical-cross:before {
  content: "\ea2a";
}

.cui-meh:before {
  content: "\ea2b";
}

.cui-menu:before {
  content: "\ea2c";
}

.cui-microchip:before {
  content: "\ea2d";
}

.cui-microphone:before {
  content: "\ea2e";
}

.cui-microphone-slash:before {
  content: "\ea2f";
}

.cui-minus:before {
  content: "\ea30";
}

.cui-minus-square:before {
  content: "\ea31";
}

.cui-mobile:before {
  content: "\ea48";
}

.cui-mobile-check:before {
  content: "\ea4e";
}

.cui-mobile-plus:before {
  content: "\ea50";
}

.cui-mobile-slash:before {
  content: "\ea51";
}

.cui-mobile-volume:before {
  content: "\ea52";
}

.cui-mobile-x:before {
  content: "\ea53";
}

.cui-money-bill-alt:before {
  content: "\ea32";
}

.cui-monitor:before {
  content: "\ea33";
}

.cui-moon:before {
  content: "\ea34";
}

.cui-move:before {
  content: "\ea35";
}

.cui-musical-note:before {
  content: "\ea36";
}

.cui-newspaper:before {
  content: "\ea37";
}

.cui-note:before {
  content: "\ea38";
}

.cui-notebook:before {
  content: "\ea39";
}

.cui-object-group:before {
  content: "\ea3a";
}

.cui-object-ungroup:before {
  content: "\ea3b";
}

.cui-options:before {
  content: "\ea3c";
}

.cui-paper-plane:before {
  content: "\ea3d";
}

.cui-paperclip:before {
  content: "\ea3e";
}

.cui-pause-circle:before {
  content: "\ea3f";
}

.cui-pen:before {
  content: "\ea40";
}

.cui-pen-alt:before {
  content: "\ea41";
}

.cui-pen-fancy:before {
  content: "\ea42";
}

.cui-pen-nib:before {
  content: "\ea43";
}

.cui-pencil:before {
  content: "\ea44";
}

.cui-people:before {
  content: "\ea45";
}

.cui-percent:before {
  content: "\ea46";
}

.cui-person:before {
  content: "\ea47";
}

.cui-phone-call:before {
  content: "\eac2";
}

.cui-phone-check:before {
  content: "\ea49";
}

.cui-phone-plus:before {
  content: "\ea4f";
}

.cui-phone-slash:before {
  content: "\ea4a";
}

.cui-phone-square:before {
  content: "\ea4b";
}

.cui-phone-volume:before {
  content: "\ea4c";
}

.cui-phone-x:before {
  content: "\ea4d";
}

.cui-pin:before {
  content: "\ea55";
}

.cui-play-circle:before {
  content: "\ea56";
}

.cui-plus:before {
  content: "\ea57";
}

.cui-plus-square:before {
  content: "\ea58";
}

.cui-power-standby:before {
  content: "\ea59";
}

.cui-print:before {
  content: "\ea5a";
}

.cui-project:before {
  content: "\ea5b";
}

.cui-pulse:before {
  content: "\ea5c";
}

.cui-puzzle:before {
  content: "\ea5d";
}

.cui-puzzle-piece:before {
  content: "\ea5e";
}

.cui-qr-code:before {
  content: "\ea5f";
}

.cui-question-circle:before {
  content: "\ea60";
}

.cui-question-mark:before {
  content: "\ea61";
}

.cui-rain:before {
  content: "\ea62";
}

.cui-random:before {
  content: "\ea63";
}

.cui-rectangle-wide:before {
  content: "\ea64";
}

.cui-recycle:before {
  content: "\ea65";
}

.cui-registered:before {
  content: "\ea66";
}

.cui-reload:before {
  content: "\ea67";
}

.cui-resize-both:before {
  content: "\ea68";
}

.cui-resize-height:before {
  content: "\ea69";
}

.cui-resize-width:before {
  content: "\ea6a";
}

.cui-rss:before {
  content: "\ea6b";
}

.cui-rss-alt:before {
  content: "\ea6c";
}

.cui-rss-square:before {
  content: "\ea6d";
}

.cui-save:before {
  content: "\ea6e";
}

.cui-screen-desktop:before {
  content: "\ea6f";
}

.cui-screen-smartphone:before {
  content: "\ea70";
}

.cui-script:before {
  content: "\ea71";
}

.cui-scrubber:before {
  content: "\ea72";
}

.cui-settings:before {
  content: "\ea73";
}

.cui-share:before {
  content: "\ea74";
}

.cui-share-all:before {
  content: "\ea75";
}

.cui-share-boxed:before {
  content: "\ea76";
}

.cui-shield:before {
  content: "\ea77";
}

.cui-shield-check:before {
  content: "\ea78";
}

.cui-sign-language:before {
  content: "\ea79";
}

.cui-signal:before {
  content: "\ea7a";
}

.cui-signpost:before {
  content: "\ea7b";
}

.cui-sitemap:before {
  content: "\ea7c";
}

.cui-smile:before {
  content: "\ea7d";
}

.cui-smile-plus:before {
  content: "\ea7e";
}

.cui-snowflake:before {
  content: "\ea7f";
}

.cui-social-facebook:before {
  content: "\ea80";
}

.cui-social-linkedin:before {
  content: "\ea81";
}

.cui-social-skype:before {
  content: "\ea82";
}

.cui-social-spotify:before {
  content: "\ea83";
}

.cui-social-twitter:before {
  content: "\ea84";
}

.cui-sort-alpha-down:before {
  content: "\ea85";
}

.cui-sort-alpha-up:before {
  content: "\ea86";
}

.cui-sort-ascending:before {
  content: "\ea87";
}

.cui-sort-descending:before {
  content: "\ea88";
}

.cui-sort-numeric-down:before {
  content: "\ea89";
}

.cui-sort-numeric-up:before {
  content: "\ea8a";
}

.cui-speech:before {
  content: "\ea8b";
}

.cui-speech-bubble:before {
  content: "\ea8c";
}

.cui-speedometer:before {
  content: "\ea8d";
}

.cui-spreadsheet:before {
  content: "\ea8e";
}

.cui-square:before {
  content: "\ea8f";
}

.cui-star:before {
  content: "\ea90";
}

.cui-star-half:before {
  content: "\ea91";
}

.cui-sticky-note:before {
  content: "\ea92";
}

.cui-stop-circle:before {
  content: "\ea93";
}

.cui-stream:before {
  content: "\ea94";
}

.cui-sun:before {
  content: "\ea95";
}

.cui-tablet:before {
  content: "\ea96";
}

.cui-tag:before {
  content: "\ea97";
}

.cui-tags:before {
  content: "\ea98";
}

.cui-target:before {
  content: "\ea99";
}

.cui-task:before {
  content: "\ea9a";
}

.cui-terminal:before {
  content: "\ea9b";
}

.cui-text:before {
  content: "\ea9c";
}

.cui-text-height:before {
  content: "\ea9d";
}

.cui-text-width:before {
  content: "\ea9e";
}

.cui-thumb-down:before {
  content: "\ea9f";
}

.cui-thumb-up:before {
  content: "\eaa0";
}

.cui-timer:before {
  content: "\eaa1";
}

.cui-trademark:before {
  content: "\eaa2";
}

.cui-transfer:before {
  content: "\eaa3";
}

.cui-trash:before {
  content: "\eaa4";
}

.cui-triangle:before {
  content: "\eaa5";
}

.cui-tty:before {
  content: "\eaa6";
}

.cui-underline:before {
  content: "\eaa7";
}

.cui-universal-access:before {
  content: "\eaa8";
}

.cui-user:before {
  content: "\eaa9";
}

.cui-user-female:before {
  content: "\eaaa";
}

.cui-user-follow:before {
  content: "\eaab";
}

.cui-user-secret:before {
  content: "\eaac";
}

.cui-user-unfollow:before {
  content: "\eaad";
}

.cui-vertical-align-bottom:before {
  content: "\eaae";
}

.cui-vertical-align-center:before {
  content: "\eaaf";
}

.cui-vertical-align-top:before {
  content: "\eab0";
}

.cui-video:before {
  content: "\eab1";
}

.cui-video-plus:before {
  content: "\eab2";
}

.cui-video-slash:before {
  content: "\eab3";
}

.cui-volume-high:before {
  content: "\eab4";
}

.cui-volume-low:before {
  content: "\eab5";
}

.cui-volume-off:before {
  content: "\eab6";
}

.cui-wallet:before {
  content: "\eab7";
}

.cui-warning:before {
  content: "\eab8";
}

.cui-wheelchair:before {
  content: "\eab9";
}

.cui-wifi:before {
  content: "\eaba";
}

.cui-window-minimize:before {
  content: "\eabb";
}

.cui-window-restore:before {
  content: "\eabc";
}

.cui-wrench:before {
  content: "\eabd";
}

.cui-x:before {
  content: "\eabe";
}

.cui-yen:before {
  content: "\eabf";
}

.cui-zoom-in:before {
  content: "\eac0";
}

.cui-zoom-out:before {
  content: "\eac1 ";
}

/*# sourceMappingURL=coreui-icons-linear.css.map */